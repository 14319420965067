import qs, { StringifiableRecord } from 'query-string';
import type { SeriesOverview } from '~/common/types/series-overview';
import type { UserOverview } from '~/common/types/user-overview';
import type { SearchContextType } from '~/features/search/types';
import { Providers } from '~/usecases/auth-use-case';
import { NameConstants } from './constants';
import { formatResultsCount } from './formats';
export const SITE_NAME = NameConstants.SITE_NAME;
export const DEFAULT_TITLE = `${SITE_NAME}`;
export const TITLE_TEMPLATE = ` | ${SITE_NAME}`;
export const PUBLICATION_TITLE_TEMPLATE = ` | 出版・メディアミックス作品紹介 | ${SITE_NAME}`;
const stripEscapeSequence = (str: string): string => str.replace('\\', '');

const createTitle = (title?: string): string =>
  title ? `${stripEscapeSequence(title)}${TITLE_TEMPLATE}` : DEFAULT_TITLE;

const createUserTitle = (
  user?: UserOverview | null,
  pageNum?: number,
  seriesList?: SeriesOverview[],
  noSiteName?: boolean
): string => {
  if (!user) {
    return ' ';
  }

  const pageSentence =
    pageNum && pageNum > 0 ? `${pageNum + 1}ページ数 - ` : '';

  let title = `${pageSentence}「${user.name}」`;

  if (seriesList?.length) {
    const [latestSeries] = seriesList;
    if (latestSeries?.title) {
      title += `最新作：${latestSeries.title}`;
    }
  }

  if (noSiteName) {
    return title;
  }

  return `${title}| ${NameConstants.SITE_NAME}`;
};

export const routes = {
  title: () => createTitle(),
  breadCrumbTitle: () => 'トップ',
  url: () => '/',
  deleteAccount: {
    title: () => 'アカウント削除',
    breadCrumbTitle: () => 'アカウント削除',
    url: (fragmentIdentifier?: string) =>
      qs.stringifyUrl(
        {
          url: `/delete_account`,
          fragmentIdentifier,
        },
        {
          skipNull: true,
        }
      ),
  },
  story: {
    title: (
      storyTitle: string,
      userName: string,
      seriesStoryCount: number,
      seriesIndex?: number
    ) => {
      return createTitle(
        `${
          seriesIndex ? `${seriesIndex}` : '1'
        }. ${storyTitle} | 全${seriesStoryCount}話 (作者:${userName})の連載小説`
      );
    },
    breadCrumbTitle: (
      storyTitle: string,
      userName: string,
      seriesIndex?: number
    ) =>
      `${seriesIndex ? `#${seriesIndex}` : '#1'} ${storyTitle} / ${userName}`,
    url: (storyId: string, query?: StringifiableRecord) =>
      qs.stringifyUrl(
        {
          url: `/s/${storyId}`,
          query: query,
        },
        {
          skipNull: true,
        }
      ),
    copyrightReport: {
      image: {
        title: () => '画像の転載の報告',
        url: (storyId: string) => `/s/${storyId}/copyright-report/image`,
      },
      text: {
        title: () => '文章の転載（権利者の方のみ）の報告',
        url: (storyId: string) => `/s/${storyId}/copyright-report/text`,
      },
    },
    comments: {
      title: (storyTitle: string, commentTotalCount: number) =>
        createTitle(`${storyTitle} （${commentTotalCount}コメント）`),
      breadCrumbTitle: (storyTitle: string) => `${storyTitle} コメント一覧`,
      url: (storyId: string, query?: StringifiableRecord) =>
        qs.stringifyUrl(
          {
            url: `/s/${storyId}/comments`,
            query: query,
          },
          {
            skipNull: true,
          }
        ),
    },
    commentReplies: {
      title: (storyTitle: string) =>
        createTitle(`${storyTitle} のコメント返信`),
      breadCrumbTitle: (storyTitle: string) => `${storyTitle} コメント返信`,
      url: (storyId: string, commentId: string, query?: StringifiableRecord) =>
        qs.stringifyUrl(
          {
            url: `/s/${storyId}/comments/${commentId}`,
            query: query,
          },
          {
            skipNull: true,
          }
        ),
    },
  },
  series: {
    title: (
      seriesTitle: string,
      userName: string,
      totalCount: number,
      page: number
    ) => {
      const pageSentence = page > 0 ? `${page + 1}ページ目 - ` : '';
      return createTitle(
        `${pageSentence}${seriesTitle} | 全${totalCount}話 (作者:${userName})の連載小説`
      );
    },
    breadCrumbTitle: (seriesTitle: string, userName: string) =>
      `${seriesTitle} / ${userName} の連載小説`,
    url: (
      seriesId: string,
      query?: StringifiableRecord,
      fragmentIdentifier?: string
    ) =>
      qs.stringifyUrl(
        {
          url: `/se/${seriesId}`,
          query: query,
          fragmentIdentifier: fragmentIdentifier,
        },
        {
          skipNull: true,
        }
      ),
    copyrightReport: {
      image: {
        title: () => '画像の転載の報告',
        url: (seriesId: string) => `/se/${seriesId}/copyright-report/image`,
      },
      text: {
        title: () => '文章の転載（権利者の方のみ）の報告',
        url: (seriesId: string) => `/se/${seriesId}/copyright-report/text`,
      },
    },
    ongoingSeries: {
      title: () => createTitle('連載中の小説（公式）一覧'),
      showTitle: () => '連載中の小説（公式）一覧',
      url: (query?: StringifiableRecord) =>
        qs.stringifyUrl(
          {
            url: '/ongoing_series',
            query: query,
          },
          {
            skipNull: true,
          }
        ),
    },
    completedSeries: {
      title: () => createTitle('完結済みの連載小説（公式）一覧'),
      showTitle: () => '完結済みの連載小説（公式）一覧',
      url: (query?: StringifiableRecord) =>
        qs.stringifyUrl(
          {
            url: '/completed_series',
            query: query,
          },
          {
            skipNull: true,
          }
        ),
    },
  },
  user: {
    userDetail: {
      title: (
        user: UserOverview,
        pageNum?: number,
        seriesList?: SeriesOverview[]
      ) => {
        return createUserTitle(user, pageNum, seriesList);
      },
      breadCrumbTitle: (
        user: UserOverview,
        pageNum?: number,
        seriesList?: SeriesOverview[]
      ) => {
        return createUserTitle(user, pageNum, seriesList, true);
      },
      url: (userId: string, query?: StringifiableRecord) =>
        qs.stringifyUrl(
          {
            url: `/user/${userId}`,
            query: query,
          },
          {
            skipNull: true,
          }
        ),
    },
  },
  tag: {
    title: () => createTitle('タグ一覧'),
    url: (query?: StringifiableRecord) =>
      qs.stringifyUrl(
        {
          url: `/tag`,
          query: query,
        },
        {
          skipNull: true,
        }
      ),
    tagDetail: {
      title: (tagName: string, totalCount: number, pageSentence?: string) =>
        createTitle(
          `${
            pageSentence ?? ''
          }【${tagName}】小説・夢小説一覧 (${totalCount}件以上)`
        ),
      breadCrumbTitleShort: (tagName: string, withSharp = false) =>
        `${withSharp ? '#' : ''}${tagName}`,
      breadCrumbTitle: (tagName: string, pageSentence?: string) =>
        `${pageSentence ?? ''}「#${tagName}」の人気小説・夢小説一覧`,
      url: (tagName: string, query?: StringifiableRecord) =>
        qs.stringifyUrl(
          {
            url: `/tag/${encodeURIComponent(tagName)}`,
            query: query,
          },
          {
            skipNull: true,
          }
        ),
    },
    tagYumeShosetsu: {
      title: (totalCount: number) =>
        createTitle(
          `人気の夢小説が${formatResultsCount(
            totalCount,
            '作品'
          )}無料で読み放題！`
        ),
      breadCrumbTitleShort: (withSharp = false) =>
        `${withSharp ? '#' : ''}夢小説`,
      breadCrumbTitle: (pageSentence?: string) =>
        `${pageSentence ?? ''}「#夢小説」の人気小説・夢小説一覧`,
      url: (query?: StringifiableRecord) =>
        qs.stringifyUrl(
          {
            url: `/tag/${encodeURIComponent('夢小説')}`,
            query: query,
          },
          {
            skipNull: true,
          }
        ),
    },
    tagList: {
      title: () => createTitle('タグ一覧'),
      breadCrumbTitle: () => 'タグ一覧',
      url: (query?: StringifiableRecord) =>
        qs.stringifyUrl(
          {
            url: `/tag`,
            query: query,
          },
          {
            skipNull: true,
          }
        ),
    },
  },
  stories: {
    title: () => createTitle('新着小説一覧'),
    breadCrumbTitle: () => '新着小説一覧',
    url: (query?: StringifiableRecord) =>
      qs.stringifyUrl(
        {
          url: `/stories`,
          query: query,
        },
        {
          skipNull: true,
        }
      ),
  },
  safety: {
    title: () => '安心安全への取り組み',
    url: () => '/safety',
  },
  handbook: {
    title: () => 'テラーノベルハンドブック',
    url: () => '/handbook',
    'community-guideline': {
      title: () => 'コミュニティガイドライン',
      url: () => '/handbook/community-guideline',
      'copyright-protection': {
        title: () => '画像アップロード時の注意（著作権編）',
        url: '/handbook/guideline/copyright-protection',
      },
    },
    'teller-novel-world': {
      title: () => 'テラーノベルが目指す世界',
      url: () => '/handbook',
    },
    action: {
      title: () => 'テラーノベルを楽しむためのアクション',
      url: () => '/handbook/action',
    },
    'keep-in-mind': {
      title: () => 'テラーノベルを楽しむための心がけ',
      url: () => '/handbook/keep-in-mind',
    },
    'inappropiate-behavior': {
      title: () => '不適切な行動を見つけた時の対処法',
      url: () => '/handbook/inappropiate-behavior',
    },
    'management-response-policy': {
      title: () => '運営の対応ポリシー',
      url: () => '/handbook/management-response-policy',
    },
  },
  legal_information: {
    title: () => '特定商取引法に基づく表記',
    url: () => '/legal_information',
  },
  legal_request: {
    title: () => '権利侵害情報の削除について',
    url: () => '/legal_request',
  },
  corporate: {
    title: () => '',
    url: () => '/corp',
  },
  provider_request: {
    title: () => '「プロバイダ責任制限法」のお手続きに関して',
    url: () => '/provider_request',
  },
  privacy: {
    title: () => 'プライバシーポリシー',
    url: () => '/privacy',
  },
  payment_services_act: {
    title: () => '資金決済法に基づく表示',
    url: () => '/payment_services_act',
  },
  sitemap: {
    showTitle: () => 'サイトマップ',
    url: () => '/sitemap',
  },
  tos: {
    title: () => '利用規約',
    url: () => '/tos',
  },
  login: {
    title: () => 'ログイン',
    url: (from?: string | null, query?: StringifiableRecord) =>
      qs.stringifyUrl(
        {
          url: '/auth/login',
          query: {
            from: from ? encodeURIComponent(from || '') : undefined,
            ...query,
          },
        },
        {
          skipNull: true,
        }
      ),
  },
  logout: {
    title: () => 'ログアウト',
    url: () => '/auth/logout',
  },
  login_required: {
    title: () => 'ログインしてください',
    url: () => '/auth/login_required',
  },
  email_login: {
    title: () => 'Emailでログイン',
    url: (from?: string | null, query?: StringifiableRecord) =>
      qs.stringifyUrl(
        {
          url: '/auth/email-login',
          query: {
            from: from ? encodeURIComponent(from || '') : undefined,
            ...query,
          },
        },
        {
          skipNull: true,
        }
      ),
  },
  sms_login: {
    title: () => '携帯電話(SMS)でログイン',
    url: (from?: string | null, query?: StringifiableRecord) =>
      qs.stringifyUrl(
        {
          url: '/auth/sms_authentication/login',
          query: {
            from: from ? encodeURIComponent(from || '') : undefined,
            ...query,
          },
        },
        {
          skipNull: true,
        }
      ),
  },
  provider_authentication: {
    login: {
      title: () => 'SNSでログイン',
      url: (
        provider: Providers,
        from?: string | null,
        fromService?: string | null,
        colorMode?: 'light' | 'dark',
        query?: StringifiableRecord
      ) =>
        qs.stringifyUrl(
          {
            url: `/auth/provider_authentication/${provider}/login`,
            query: {
              from: from ? encodeURIComponent(from || '') : undefined,
              fromService: fromService
                ? encodeURIComponent(fromService || '')
                : undefined,
              colorMode,
              ...query,
            },
          },
          {
            skipNull: true,
          }
        ),
    },
    register: {
      title: () => 'SNSで登録',
      url: () => '/auth/provider_authentication',
    },
  },
  register: {
    title: () => '新規登録',
    url: (
      directlyToPlanSelection?: boolean,
      from?: string | null,
      query?: StringifiableRecord
    ) =>
      qs.stringifyUrl(
        {
          url: '/auth/register',
          query: {
            p: directlyToPlanSelection ? '1' : undefined,
            from: from ? encodeURIComponent(from || '') : undefined,
            ...query,
          },
        },
        {
          skipNull: true,
        }
      ),
  },
  email_registration: {
    title: () => 'Emailではじめる',
    url: (from?: string | null, query?: StringifiableRecord) =>
      qs.stringifyUrl(
        {
          url: '/auth/email_registration',
          query: {
            from: from ? encodeURIComponent(from || '') : undefined,
            ...query,
          },
        },
        {
          skipNull: true,
        }
      ),
  },
  lp_email_registration: {
    title: () => 'Emailではじめる',
    url: (lpEmail: string, from?: string | null, query?: StringifiableRecord) =>
      qs.stringifyUrl(
        {
          url: `/auth/email_registration/${lpEmail}`,
          query: {
            from: from ? encodeURIComponent(from || '') : undefined,
            ...query,
          },
        },
        {
          skipNull: true,
        }
      ),
  },
  sms_register: {
    title: () => '携帯電話(SMS)ではじめる',
    url: (from?: string | null, query?: StringifiableRecord) =>
      qs.stringifyUrl(
        {
          url: '/auth/sms_authentication/register',
          query: {
            from: from ? encodeURIComponent(from || '') : undefined,
            ...query,
          },
        },
        {
          skipNull: true,
        }
      ),
  },
  finish_register: {
    title: () => 'ユーザー登録',
    url: () => '/auth/finish_register',
  },
  registration_finished: {
    // Not migrated yet
    title: () => 'ユーザー登録完了',
    url: () => '/registration_finished',
  },
  recent_login_different_account: {
    // Not migrated yet
    title: () => '再ログインエラー',
    url: () => '/recent_login_different_account',
  },
  link_email: {
    // Not migrated yet
    title: () => 'メールアドレスを連携',
    url: () => '/link_email',
  },
  link_email_confirmation: {
    // Not migrated yet
    title: () => 'メールアドレスを連携',
    url: () => '/link_email_confirmation',
  },
  reset_password: {
    title: () => 'パスワード再発行',
    url: () => '/reset_password',
  },

  settings: {
    sensitive: {
      title: () => 'センシティブな内容の設定',
      url: () => '/settings/sensitive',
    },
  },
  home: {
    title: () => `${NameConstants.SITE_NAME_WITH_EN}｜無料の小説投稿サイト`,
    breadCrumbTitle: () =>
      `${NameConstants.SITE_NAME_WITH_EN} ： 人気のWebノベル | 小説投稿サイト`,
    url: (query?: StringifiableRecord) =>
      qs.stringifyUrl(
        {
          url: '/',
          query: query,
        },
        {
          skipNull: true,
        }
      ),
  },
  material_license: {
    title: () => '素材ライセンス',
    url: () => '/material_license',
  },
  rankingSeries: {
    title: () => createTitle('人気連載小説（公式）ランキング '),
    breadCrumbTitle: () => '人気連載小説（公式）ランキング ',
    url: (query?: StringifiableRecord) =>
      qs.stringifyUrl(
        {
          url: '/ranking/series',
          query: query,
        },
        {
          skipNull: true,
        }
      ),
  },
  users: {
    title: () => createTitle('小説家一覧'),
    breadCrumbTitle: () => '小説家一覧',
    url: (query?: StringifiableRecord) =>
      qs.stringifyUrl(
        {
          url: '/users',
          query: query,
        },
        {
          skipNull: true,
        }
      ),
  },
  myPage: {
    title: () => createTitle('マイページ／設定'),
    breadCrumbTitle: () => 'マイページ／設定',
    url: () => '/my_page',
  },
  profileEdit: {
    title: () => createTitle('プロフィール編集'),
    url: () => '/profile_edit',
  },
  search: {
    title: (keyword?: string) => createTitle(`「${keyword}」の検索結果`),
    showTitle: (keyword?: string) => `「${keyword}」の検索結果`,
    breadCrumbTitle: (keyword?: string) => `「${keyword}」の検索結果`,
    url: (
      decodedKeyword: string,
      context?: SearchContextType,
      query?: StringifiableRecord
    ) =>
      qs.stringifyUrl(
        {
          url: `/search`,
          query: {
            q: decodedKeyword,
            context,
            ...query,
          },
        },
        {
          skipNull: true,
        }
      ),
  },
  notifications: {
    title: () => createTitle('お知らせ'),
    breadCrumbTitle: () => 'お知らせ',
    url: (query?: StringifiableRecord) =>
      qs.stringifyUrl(
        {
          url: '/notifications',
          query: query,
        },
        {
          skipNull: true,
        }
      ),
  },
  genre: {
    ranking: {
      title: (genreName: string) =>
        createTitle(`${genreName}の人気小説ランキング`),
      breadCrumbTitle: (genreName: string) => `「${genreName}」の人気小説`,
      url: (genreName: string, query?: StringifiableRecord) =>
        qs.stringifyUrl(
          {
            url: `/genre/${genreName}/ranking`,
            query: query,
          },
          {
            skipNull: true,
          }
        ),
    },
  },
  bookshelf: {
    title: () => createTitle('閲覧履歴'),
    breadcrumbTitle: () => '閲覧履歴',
    url: () => '/bookshelf',
  },
  profile: {
    planSelection: {
      title: () => 'お支払い方法の選択',
      breadcrumbTitle: () => 'お支払い方法の選択',
      url: (from?: 'lp' | 'perklp', query?: StringifiableRecord) =>
        qs.stringifyUrl(
          {
            url: '/profile/plan_selection',
            query: {
              from,
              ...query,
            },
          },
          {
            skipNull: true,
          }
        ),
    },
    planConfirmation: {
      title: () => 'お支払い方法の選択', // TODO: is this title ok? is showing when attempting to subscribe the user
      breadcrumbTitle: () => 'お支払い方法の選択',
      url: () => '/profile/plan_confirmation',
    },
    subscriptionPlan: {
      title: () => 'プラン・お支払い情報',
      breadcrumbTitle: () => 'プラン・お支払い情報',
      url: () => '/profile/subscription_plan',
    },
  },
  subscriptionConfirmed: {
    // Still in old web
    title: () => '登録完了',
    url: () => '/subscription_confirmed',
  },
  publication: {
    title: () => createTitle('出版・メディアミックス作品紹介'),
    breadCrumbTitle: () => '出版・メディアミックス作品紹介',
    url: (query?: StringifiableRecord) =>
      qs.stringifyUrl(
        {
          url: '/publication',
          query: query,
        },
        {
          skipNull: true,
        }
      ),

    detail: {
      title: (title: string) =>
        `${stripEscapeSequence(
          title
        )} | 出版・メディアミックス作品紹介 | ${SITE_NAME}`,
      breadCrumbTitle: (title: string) => title,
      url: (publicationId: string, query?: StringifiableRecord) =>
        qs.stringifyUrl(
          {
            url: `/publication/${publicationId}`,
            query: query,
          },
          {
            skipNull: true,
          }
        ),
    },
  },
  drama: {
    home: {
      url: () => '/short-drama',
    },
  },
};
